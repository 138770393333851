input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f8f8f8;
}

.container {
    width: 90%;
    max-width: 800px;
    margin: 50px auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

h1 {
    text-align: center;
    color: #333;
    font-size: 24px;
    margin-bottom: 20px;
}

.search-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.form-group {
    width: 48%;
    margin-bottom: 15px;
    position: relative;
}

.record-count {
    display: flex;
    justify-content: center;
    padding-bottom: 1rem;
    font-weight: bold;
}
.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
}

.form-group input[type="text"],
.form-group input[type="number"],
.state-selection {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    transition: border-color 0.3s;
}

.form-group input[type="text"]:focus,
.form-group input[type="number"]:focus,
.state-selection:focus {
    border-color: #007BFF;
    outline: none;
}

.checkbox-group {
    width: 48%;
    display: flex;
    align-items: center;
}

.checkbox-group label {
    margin-right: 10px;
    font-weight: bold;
    color: #555;
}

.checkbox-group input[type="checkbox"] {
    transform: scale(1.5);
}

.search-button {
    width: 100%;
    padding: 15px;
    background-color: #168A53;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.search-button:hover {
    background-color: #199E5F;
}

.results {
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;
    max-width: 78rem;
}

.listing {
    background-color: #fff;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.listing-details {
    margin-bottom: 10px;
}

.location, .additional-details {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    color: black;
}

.location div, .additional-details div {
    margin-right: 5px;
}

@media (max-width: 768px) {
    .form-group {
        width: 100%;
    }

    .checkbox-group {
        width: 100%;
    }
}
