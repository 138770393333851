.App-body {
  background-image: url(./background.webp); /*Path to your image*/
  background-size: cover; /* Cover the entire page */
  background-repeat: no-repeat; /* Do not repeat the image */
}

.App {
  text-align: center;
}
.App-link {
  color: #61dafb;
}

.App-menu-text {
  display: flex;
  flex-direction: row;
  gap: 10;
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
