body {
    font-family: 'Arial', sans-serif;
    background-color: #f8f8f8;
    margin: 0;
    padding: 0;
  }
  
  .signup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
  }
  
  .signup-form {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 600px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .signup-input {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    transition: border-color 0.3s;
  }
  
  .signup-input:focus {
    border-color: #168A53;
    outline: none;
  }
  
  .signup-button {
    width: 100%;
    padding: 15px;
    background-color: #168A53;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 18px;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s;
    margin-top: 20px;
  }
  
  .signup-button:hover {
    background-color: #199E5F;
  }

  .styled-text-error {
    padding: 0.5rem;
    background-color: #e70e0e;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    color: #f5f2f2;
  }
  