body {
    font-family: 'Arial', sans-serif;
    background-color: #f8f8f8;
    margin: 0;
    padding: 0;
  }
  
  .login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
  }
  
  .login-header {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .login-form {
    width: 90%;
    max-width: 400px;
  }
  
  .login-card {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .login-card-header {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .login-input {
    width: 100%;
    padding: 12px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    transition: border-color 0.3s;
  }
  
  .login-input:focus {
    border-color: #168A53;
    outline: none;
  }
  
  .login-button {
    width: 100%;
    padding: 15px;
    background-color: #168A53;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 18px;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s;
    margin-bottom: 20px;
  }
  
  .login-button:hover {
    background-color: #199E5F;
  }
  
  .login-links {
    text-align: center;
  }
  
  .login-link-item {
    margin-bottom: 10px;
  }
  
  .link {
    color: #199E5F;
    font-weight: bold;
    text-decoration: none;
  }
  
  .link:hover {
    text-decoration: underline;
  }
  