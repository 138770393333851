body {
    margin: 0;
    font-family: 'Arial', sans-serif;
    background-color: #f8f8f8;
}

.logo {
    width: 100%;
    height: auto;
}

.header {
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    z-index: 1000;
}

.logo-container {
    width: 100%;
    text-align: center;
    /* background-color: #199E5F; Adjust background to match banner style */
    padding: 1px 0;
}

.container {
    width: 100%;
    max-width: 78rem;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.nav {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.nav-links {
    display: flex;
    align-items: center;
    gap: 20px;
}

.nav-link {
    text-decoration: none;
    font-weight: bold;
    color: #555;
}

.nav-link:hover {
    color: #168A53;
}

.btn-primary {
    display: inline-block;
    padding: 10px 20px;
    background-color: #168A53;
    color: #fff;
    border-radius: 4px;
    transition: background-color 0.3s;
    cursor: pointer;
    text-align: center;
}

.btn-primary:hover {
    background-color: #199E5F;
}

@media (max-width: 768px) {
    .nav {
        flex-direction: column;
        align-items: center;
    }

    .nav-links {
        flex-direction: column;
        gap: 10px;
    }
}
