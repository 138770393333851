body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    color: #333;
    background-color: #f9f9f9;
}
.header {
    background-color: #199E5F;
    color: white;
    padding: 20px 0;
    text-align: center;
}
.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}
.hero {
    width: 100%;
    height: auto;
    /* height: 400px;
    background-size: cover;
    background-position: center; */
}
.hero-content {
    text-align: center;
    padding: 50px 20px;
}
.hero-content h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
}
.hero-content p {
    font-size: 1.2em;
    margin-bottom: 40px;
}
.button {
    background-color: #199E5F;
    color: white;
    padding: 15px 30px;
    text-decoration: none;
    border-radius: 5px;
    font-size: 1.2em;
}
.features {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 40px 0;
}
@media (max-width: 600px) {
    .features {
        flex-direction: column;
    }
}
.feature {
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 20px;
    flex: 1 1 30%;
    margin: 10px;
    text-align: center;
}
.feature h3 {
    margin-bottom: 15px;
    color: #199E5F;
}
.footer {
    background-color: #333;
    color: white;
    text-align: center;
    padding: 20px;
    margin-top: 40px;
}