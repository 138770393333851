.container-tos {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1, h2 {
    color: #333;
}

h1 {
    font-size: 2em;
    margin-bottom: 20px;
}

h2 {
    font-size: 1.5em;
    margin-top: 20px;
    margin-bottom: 10px;
}

p, ol, ul {
    margin-bottom: 20px;
}

ol, ul {
    padding-left: 20px;
}

li {
    margin-bottom: 10px;
}

ul li {
    list-style-type: disc;
}

ol li {
    list-style-type: decimal;
}
