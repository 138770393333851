input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f8f8f8;
}

.container {
    width: 90%;
    max-width: 800px;
    margin: 50px auto;
    padding: 20px;
    /* background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px; */
}

h1 {
    text-align: center;
    color: #333;
    font-size: 24px;
    margin-bottom: 20px;
}

.edit-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.form-group {
    width: 48%;
    margin-bottom: 15px;
    position: relative;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
}

.form-group input[type="text"],
.state-selection {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    transition: border-color 0.3s;
}

.broker-commission {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    transition: border-color 0.3s;
}

.form-group input[type="text"]:focus,
.state-selection:focus {
    border-color: #168A53;
    outline: none;
}

.checkbox-group {
    width: 48%;
    display: flex;
    align-items: center;
}

.checkbox-group label {
    margin-right: 10px;
    font-weight: bold;
    color: #555;
}

.checkbox-group input[type="checkbox"] {
    transform: scale(1.5);
}

.save-button {
    width: 100%;
    padding: 15px;
    background-color: #168A53;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.save-button:hover {
    background-color: #199E5F;
}

.styled-text {
    padding: 0.5rem;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    color: #333;
  }

@media (max-width: 768px) {
    .form-group {
        width: 100%;
    }

    .checkbox-group {
        width: 100%;
    }
}
